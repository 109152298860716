export { default as daFilled } from "./da-filled.svg";
export { default as daOutline } from "./da-outline.svg";
export { default as daSolid } from "./da-solid.svg";
export { default as hint } from "./hint.svg";
export { default as pinFilled } from "./pin-filled.svg";
export { default as pinOutline } from "./pin-outline.svg";
export { default as google } from "./google.svg";
export { default as microsoft } from "./microsoft.svg";
export { default as slashSolid } from "./slash-solid.svg";
export { default as trashSolid } from "./trash-solid.svg";
export { default as exit } from "./exit.svg";
export { default as warning } from "./warning.svg";